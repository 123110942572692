import styled from "styled-components";

import { black } from "theme/colors";
import { p } from "theme/typography";

export const Wrapper = styled.div`
  position: relative;
  left: 0;
  width: 100%;
  padding: 7px 0;
  background-color: ${black.default};
  z-index: 4;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    p {
      text-align: center;
    }
    a {
      ${p}
    }
  }
  @media (min-width: 769px) {
    .container {
      flex-direction: row;
    }
  }
`;
