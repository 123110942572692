import { Link } from "gatsby";
import styled from "styled-components";

import { white } from "theme/colors";
import { ColorAnim } from "theme/animations";

export const StyledLink = styled(Link)`
  position: relative;
  z-index: 2;
  color: ${white.default};
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    bottom: 0;
    right: 0;
    transition: 0.4s ease;
    ${ColorAnim};
  }
  &:hover {
    &::before {
      width: 100%;
      left: 0;
    }
  }
`;

export const StyledLinkA = styled.a`
  position: relative;
  z-index: 2;
  color: ${white.default};
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    bottom: 0;
    right: 0;
    transition: 0.4s ease;
    ${ColorAnim};
  }
  &:hover {
    &::before {
      width: 100%;
      left: 0;
    }
  }
`;

