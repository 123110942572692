import React from "react";

import { graphql, Link, useStaticQuery } from "gatsby";

import { StyledLinkA } from "components/common/Links";
import AfterFooter from "components/footer/afterFooter";
import PreFooter from "components/footer/preFooter";

import { FContainer, Logo } from "./Footer.styles";

const Footer = (props) => {
  //PROPS
  const { isHome, lang } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query Footer {
      allWpPage(filter: { id: { eq: "cG9zdDo1MTE=" } }) {
        edges {
          node {
            id
            footer {
              address
              socialMedia {
                smedia {
                  target
                  title
                  url
                }
              }
              logo {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    allWpPage: { edges },
  } = data;
  //DATA

  //DEFS
  const footer = edges[0].node.footer;
  //DEFS

  //console.log(lang);

  if (!isHome) {
    return (
      <>
        <PreFooter />
        <FContainer className="Tfooter">
          <div className="container">
            <div className="col">
              <ul className="col-smedia">
                {footer.socialMedia.map((smedia, index) => {
                  return (
                    <li key={index}>
                      <StyledLinkA href={smedia.smedia.url}>
                        {smedia.smedia.title}
                      </StyledLinkA>
                    </li>
                  );
                })}
              </ul>
            </div>

            <Logo>
              <Link to="/">
                dev<span>studio</span>
              </Link>
            </Logo>
            <div className="cta">
              {lang === "PT" ? (
                <Link
                  to="/get-in-touch"
                  className="samples"
                  aria-label="Link para entrar em contacto."
                >
                  <img alt="Link para entrar em contacto." src="/send.png" />
                </Link>
              ) : (
                <Link
                  to="/en/get-in-touch"
                  className="samples"
                  aria-label="Link for get in touch."
                >
                  <img alt="Link for get in touch." src="/send.png" />
                </Link>
              )}
            </div>
          </div>
        </FContainer>
        <AfterFooter lang={lang} />
      </>
    );
  } else {
    return (
      <FContainer isHome={isHome}>
        <div className="container">
          <div className="col">
            <ul className="col-smedia">
              {footer.socialMedia.map((smedia, index) => {
                return (
                  <li key={index}>
                    <StyledLinkA href={smedia.smedia.url}>
                      {smedia.smedia.title}
                    </StyledLinkA>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="cta">
            {lang === "PT" ? (
              <Link
                to="/get-in-touch"
                className="samples"
                aria-label="Link para entrar em contacto."
              >
                <img alt="Link para entrar em contacto." src="/send.png" />
              </Link>
            ) : (
              <Link
                to="/en/get-in-touch"
                className="samples"
                aria-label="Link for get in touch."
              >
                <img alt="Link for get in touch." src="/send.png" />
              </Link>
            )}
          </div>
        </div>
      </FContainer>
    );
  }
};

export default Footer;
