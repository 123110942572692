import React from "react";

import Ticker from "react-ticker";

import { Pre } from "./PreFooter.styles";

const PreFooter = (props) => {
  return (
    <Pre className="coloredB Tfooter">
      <div className="mk">
        <h4>
          making simple <span>.</span>
        </h4>
      </div>
      <Ticker direction="toRight" mode="chain" speed={1} offset="100%">
        {() => (
          <p className="line">
            <span style={{ marginRight: "12px" }}>
              web<b>design</b>
            </span>
            <span style={{ marginRight: "12px" }}>
              landing<b>pages</b>
            </span>
            <span style={{ marginRight: "12px" }}>
              web<b>apps</b>
            </span>
            <span style={{ marginRight: "12px" }}>
              marketing
              <b>digital</b>
            </span>
          </p>
        )}
      </Ticker>
      <Ticker direction="toLeft" mode="chain" speed={1} offset="-100%">
        {() => (
          <>
            <p className="line">
              <span style={{ marginRight: "12px" }}>
                <b>arquitectura</b>
              </span>
              <span style={{ marginRight: "12px" }}>
                reabilitação<b>espaços</b>
              </span>
              <span style={{ marginRight: "12px" }}>
                <b>serralharia</b>
              </span>
              <span style={{ marginRight: "12px" }}>
                loja<b>online</b>
              </span>
            </p>
          </>
        )}
      </Ticker>
      <Ticker direction="toRight" mode="chain" speed={1} offset="100%">
        {() => (
          <>
            <p className="line">
              <span style={{ marginRight: "12px" }}>
                gestão<b>redes</b>sociais
              </span>
              <span style={{ marginRight: "12px" }}>
                design<b>interiores</b>
              </span>
              <span style={{ marginRight: "12px" }}>
                engenharia
                <b>civil</b>
              </span>
              <span style={{ marginRight: "12px" }}>
                <b>branding</b>
              </span>
            </p>
          </>
        )}
      </Ticker>
      <Ticker direction="toLeft" mode="chain" speed={1} offset="-100%">
        {() => (
          <>
            <p className="line">
              <span style={{ marginRight: "12px" }}>
                <b>fotografia</b>
              </span>
              <span style={{ marginRight: "12px" }}>
                <b>vídeo</b>
              </span>
              <span style={{ marginRight: "12px" }}>
                render<b>3d</b>
              </span>
              <span style={{ marginRight: "12px" }}>
                modelização
                <b>3d</b>
              </span>
            </p>
          </>
        )}
      </Ticker>
    </Pre>
  );
};

export default PreFooter;
